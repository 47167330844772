<template>
  <div class="lg:w-5/12 xl:w-3/12 bg-black text-white flex-col items-center pt-40 lg:flex hidden h-full">
    <ul>
      <li v-for="(step, index) in steps" :key="index" class="mb-8 rounded-full pl-3 py-2 pr-12"
          :class="{'bg-pureBlack':currentStep === step.stepNumber}">
        <div class="flex items-center">
          <CircleCheckIcon :fillColor="circleColor(step)" :transparent="transparentCircle(step.stepNumber)" :withoutCheck="withoutCheck(step.stepNumber, step.type)"/>
          <span class="font-semibold" :class="{'text-darkGray': stepDisabled(step.type)}">{{step.name}}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import CircleCheckIcon from "../svgs/CircleCheckIcon";

  export default {
    name: "StepsColumn",
    components: {CircleCheckIcon},
    props: {
      steps: Array,
      currentStep: Number
    },

    computed: {
      profileType() {
        return this.$store.getters['auth/profileType']
      },
    },

    methods: {
      circleColor(step){
        const stepNumber = step.stepNumber;
        let color = '';
        switch (true) {
          case this.stepDisabled(step.type):
            color = '#373738'
            break;
          case stepNumber > this.currentStep:
            color = '#525252'
            break;
          case stepNumber < this.currentStep:
            color = '#1FD76C'
            break;
          default:
            color = 'none'
            break;
        }
        return color;
      },

      transparentCircle(stepNumber){
        return stepNumber == this.currentStep
      },

      withoutCheck(stepNumber, stepType){
        return stepNumber > this.currentStep && !this.stepDisabled(stepType)
      },

      stepDisabled(stepType){
        return stepType && this.profileType && this.profileType != 'both' && stepType != this.profileType
      }
    },
  }
</script>

<style scoped>

</style>
