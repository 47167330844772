<template>
  <svg class="mr-6" width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="15.5"
      cy="15.5"
      :r="transparent ? '14.5': '15.5'"
      :stroke="transparent ? 'white': ''"
      :stroke-width="transparent ? '2': ''"
      :fill="fillColor"
    />
    <path d="M20 12L13.8125 19L11 15.8182" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" v-if="!withoutCheck"/>
  </svg>
</template>

<script>
export default {
  name: 'CircleCheckIcon',
  props: {
    fillColor: {
      type: String,
      default: 'none',
      required: false
    },
    borderColor: {
      type: String,
      default: 'none',
      required: false
    },
    transparent: {
      type: Boolean,
      default: true,
      required: false
    },
    withoutCheck: {
      type: Boolean,
      default: false,
      required: false
    },

  }
}
</script>

<style lang="css" scoped></style>