<template>
  <div class="flex relative h-full">
    <!--Left side-->
    <StepsColumn :steps="steps" :currentStep="currentStep"/>
    <!--Right side-->
    <div class="w-full lg:w-7/12 xl:w-9/12 flex justify-center">
      <div class="rounded-lg text-black relative p-8 my-16 stepContainer">
        <!-- Header -->
        <div class="flex justify-between mb-10">
          <h4 class="text-4xl font-bold flex items-center">
            <span class="mr-4 title">{{mainTitle}}</span>
            <button @click="skip" v-if="currentStep == 4 || currentStep == 5" class="text-pink-500 uppercase font-semibold text-sm">Skip
            </button>
          </h4>
          <!-- Step counter -->
          <StepsCounter :stepCount="steps.length" :currentStep="currentStep"/>
        </div>
        <!-- BODY -->
        <div class="mb-10">
          <router-view></router-view>
        </div>
        <!-- Buttons-->
        <div class="flex" :class="currentStep === 1 ? 'justify-end' :'justify-between'">
          <button v-if="currentStep > 1" class="text-pink-500 font-semibold uppercase" @click="prev">Previous Step
          </button>
          <button @click="next" class=" rounded-full px-10 py-3 bg-indigo-gradiant text-white disabled:opacity-50" :disabled="loading">
            <span v-if="loading">
              <loading-icon class="h-5 w-5"/>
            </span>
            <span v-else>Save & continue</span>
          </button>
        </div>

        <modal ref="termsModal" class="hidden">
          Terms and conditions

          <template #content>
            <div class="w-full max-h-screen py-4 md:py-10 md:px-8 lg:py-12 lg:px-12 flex flex-col items-center justify-center">
              <div class="bg-white flex-1 md:mx-8 lg:mx-12 lg:max-w-6xl w-full rounded-3xl flex flex-col overflow-hidden">
                <div class="flex-shrink-0 pt-10">
                  <h2 class="text-xl font-bold text-center">Terms and Conditions</h2>
                </div>
                <div class="terms mt-8 flex-1 ml-6 mr-3 md:mx-16 lg:mx-24 pr-3 md:pr-6 lg:pr-8 overflow-y-scroll">
                  <TermsAndConditions />
                </div>
                <div class="my-6 flex flex-col md:flex-row items-center justify-center md:space-x-16">
                  <button @click.prevent="declineTerms" type="button" class="order-2 md:order-1 uppercase text-pink-500 font-semibold mt-4 md:mt-0">Decline</button>
                  <button @click.prevent="acceptTerms" type="button" class="btn order-1 md:order-2">Accept</button>
                </div>
              </div>
            </div>
          </template>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapMutations} from "vuex";
  import StepsCounter from "@/components/StepsCounter";
  import StepsColumn from "../../components/register/StepsColumn";
  import {find} from 'lodash'
  import Modal from '@/components/shared/Modal'
  import TermsAndConditions from '@/components/register/TermsAndConditions'

  export default {
    name: "Register",
    components: {
      StepsColumn,
      StepsCounter,
      Modal,
      TermsAndConditions,
    },
    data() {
      return {
        loading: false,
        showTermsModal: false,
      };
    },
    methods: {
      ...mapMutations({
        clearShowForm: "register/clearShowForm"
      }),

      next() {
        switch (this.currentStep) {
          case 1:
            this.registerUser();
            break;
          case 2:
            this.createSubscription();
            break;
          case 3:
            this.updateUser();
            break;
          case 4:
            this.createGuest();
            break;
          case 5:
            this.createShow();
            break;
          case 6:
            this.addGuestLinks();
            break;
          case 7:
            this.saveAvailability();
            break;
          default:
            this.goToThankYouPage();
            break;
        }
      },
      prev() {
        const currentStep = this.currentStep;
        if (currentStep == 5 && this.profileType == 'guest' || currentStep == 4 && this.profileType == 'show') {
            this.currentStep = this.currentStep - 2;
        } else if (this.currentStep == 7 && !this.guest.name) {
            this.currentStep = 5;
        } else {
          this.currentStep--;
        }


        if(currentStep == 7 && !this.guest.name) {
          this.$router.push(this.routes[3])
        } else {
          const route = this.routes[this.routeIndex - 1];
          if (route.name == 'register-profile-type') {
            return this.setUserTypeNull();
          }
          this.$router.push({name: route.name})
        }
      },
      skip() {
        window.Event.$emit('skipForm', this.currentStep - 4)
        if (this.currentStep == 4 && this.profileType == 'guest') {
          this.currentStep += 2;
          this.goToThankYouPage();
        } else if (this.currentStep == 5 && !this.guest.id) {
          this.currentStep += 2;
          this.$router.push({name: 'register-availability'})
        } else{
          this.currentStep++;
        }
      },
      fetchTags() {
        this.loading = true
        this.$store.dispatch('register/fetchTags')
          .catch(error => {
            if (error.message) {
              console.log(error.message)
            }
          })
          .finally(() => this.loading = false)
      },
      registerUser() {
        if (! this.userForm.terms_accepted) {
          this.openTermsModal()
          return
        }

        this.loading = true;
        var self = this;
        const register = this.authenticated ? 'auth/updateUser' : 'auth/register';
        this.$store.dispatch(register, this.userForm)
          .then(response => {
            if (this.$route.query.invited) {
              return this.$router.push({name: 'Home'})
            }
            if (response) {
              const route = self.routes[self.routeIndex + 1];
              self.$router.push({name: route.name});
              this.currentStep++;
            }
          })
          .catch(errors => {
            if (errors.response.data) {
              if (errors.response.data.message) {
                return this.$toast.error(errors.response.data.message)
              }
              else if (errors.response.data.errors) {
                window.Event.$emit('userFormErrors', errors.response.data.errors)
              }
            }
          })
          .finally(() => self.loading = false)
      },
      updateUser() {
        this.loading = true;
        var self = this;
        const data = {type: this.userForm.type};
        this.$store.dispatch('auth/updateUser', data).then(() => {
          const route = self.routes[self.routeIndex + 1];
          self.$router.push({name: route.name});
          if (self.userForm.type == 'show') {
            self.currentStep = self.currentStep + 2;
          } else {
            self.currentStep++;
          }
        }).catch(errors => {
          self.$refs.form.setErrors(errors.response.data.errors)
        })
          .finally(() => self.loading = false)
      },
      createSubscription() {
        window.Event.$emit('createSubscription')
      },
      setUserTypeNull(){
        this.loading = true;
        var self = this;
        const data = {type: null};
        this.$store.dispatch('auth/updateUser', data)
        .then(() => {
          self.$router.push({name: 'register-profile-type'});
        })
        .finally(() => this.loading = false)
      },
      createGuest() {
        this.loading = true;
        var self = this;
        this.$store.dispatch('guest/create', this.guestForm)
          .then(() => this.$store.dispatch('auth/getUser'))
          .then(() => {
            window.Event.$emit('profileFormSubmitted', 'guest')

            if (self.userForm.type == 'both') {
              self.currentStep++;
              }else{
              self.currentStep = self.currentStep + 2;
            }
          })
          .catch(errors => {
            window.Event.$emit('guestFormErrors', errors.response.data.errors)
          })
          .finally(() => self.loading = false)
      },
      createShow() {
        this.loading = true;
        var self = this;
        this.$store.dispatch('shows/create', this.showForm)
          .then(() => this.$store.dispatch('auth/getUser'))
          .then(() => {
            this.clearShowForm()

            self.currentStep = self.currentStep + 2;
            if(this.guest.name) {
              this.$router.push({name: 'register-guest-social-links'})
            } else {
              this.$router.push({name: 'register-availability'})
            }
          })
          .catch(errors => {
            if (errors.response) {
              window.Event.$emit('showFormErrors', errors.response.data.errors)
            }
          })
          .finally(() => this.loading = false)
      },
      addGuestLinks() {
        this.loading = true;
        var self = this;
        self.currentStep++;
        this.$router.push({name: 'register-availability'})
        .then(() => { this.loading = false; });
      },
      saveAvailability() {
        // refresh user store
        this.$store.dispatch('auth/getUser');

        window.Event.$emit('saveAvailability')

        // this.loading = true;
      },
      goToThankYouPage(){
        if (this.user.status === 'pending') {
          return this.$router.push({ name: 'CheckEmail' });
        }

        this.$router.push({name: 'ThankYou'})
      },

      openTermsModal() {
        this.$refs.termsModal.toggle()
      },

      acceptTerms() {
        this.userForm.terms_accepted = true
        this.$refs.termsModal.close()
        this.registerUser()
      },

      declineTerms() {
        this.userForm.terms_accepted = false
        this.$refs.termsModal.close()
      }
    },

    computed: {
      ...mapState('register', {
        steps: state => state.steps,
        userForm: state => state.userForm,
        guestForm: state => state.guestForm,
        showForm: state => state.showForm,
      }),

      user() {
        return this.$store.getters['auth/getUser'];
      },

      mainTitle() {
        let step = find(this.steps, ['stepNumber', this.currentStep]);
        return step.name;
      },

      routes() {
        return this.$router.options.routes.find(r => r.name === 'register').children;
      },

      routeIndex() {
        return this.routes.findIndex(r => r.name === this.$route.name);
      },

      profileType() {
        return this.$store.getters['auth/profileType']
      },

      authenticated() {
        return this.$store.getters['auth/authenticated']
      },

      guest() {
        return this.$store.getters['guest/getGuest']
      },

      currentStep: {
        get() {
          return this.$store.getters['register/currentStep']
        },
        set(newValue) {
          return this.$store.dispatch('register/setCurrentStep', newValue)
        }
      }
    },

    mounted() {
      this.fetchTags();
    },

    beforeDestroy() {
      this.currentStep = 1
    }
  }
  ;
</script>

<style>
.stepContainer
{
  width:614px;
}
/* Works on Firefox */
.terms {
  scrollbar-width: thin;
  scrollbar-color: #C130EE #D9DDEC;
}

/* Works on Chrome, Edge, and Safari */
.terms::-webkit-scrollbar {
  width: 5px;
}

.terms::-webkit-scrollbar-track {
  background: #D9DDEC;
  border-radius: 9999px;
}

.terms::-webkit-scrollbar-thumb {
  background-color: #C130EE;
  border-radius: 9999px;
  /*border: 1px solid #D9DDEC;*/
}

@media only screen and (max-width: 320px) {
  .title{
    font-size: 2rem;

  }
 }
</style>
